import React, { useState, useEffect } from "react";
import { PauseCircle, PlayCircle, RotateCcw, Square, Clock } from "lucide-react";
import {
  Button 
} from "@mui/material";
import { useNavigate } from 'react-router-dom'; 

const FullScreenTimer = ({ initialDuration = 300, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState(initialDuration);
  const [isRunning, setIsRunning] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [customDuration, setCustomDuration] = useState<number | null>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [initialTimeLeft, setInitialTimeLeft] = useState(initialDuration);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const navigate = useNavigate(); 
  // Styles object
  const styles = {
    timerContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: "'Inter', sans-serif",
      background: 'linear-gradient(135deg, #A8EEC2 0%, #A1CFFE 50%, #C4C6FA 100%)',
    } as React.CSSProperties,
    timerCard: {
      // background: 'rgba(255, 255, 255, 0.95)',
      // borderRadius: '16px',
      // padding: '2rem',
      // boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
      // backdropFilter: 'blur(10px)',
      // width: '420px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    } as React.CSSProperties,
    quickSelectContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gap: '1rem',
      width: '100%',
      marginBottom: '1.5rem'
    },
    quickSelectButton: {
      background: 'rgba(255, 255, 255, 0.9)',
      color: '#4F46E5',
      border: 'none',
      padding: '0.5rem',
      width: '56px',
      height: '56px',
      borderRadius: '8px',
      fontSize: '0.875rem',
      fontWeight: '500',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.2s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: '#ffffff',
        transform: 'scale(1.05)'
      }
    } as React.CSSProperties,
    buttonNumber: {
      fontSize: '1.25rem',
      fontWeight: '600',
      marginBottom: '2px'
    },
    buttonUnit: {
      fontSize: '0.75rem',
      opacity: 0.8
    },
    customButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      color: '#4F46E5',
      padding: '0.75rem 1rem',
      borderRadius: '8px',
      width: '100%',
      border: 'none',
      cursor: 'pointer',
      fontSize: '0.875rem',
      fontWeight: '500',
      transition: 'all 0.2s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: '#ffffff'
      }
    },
    input: {
      width: '100%',
      padding: '0.75rem 1rem',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '8px',
      fontSize: '1rem',
      marginBottom: '1rem',
      outline: 'none',
      transition: 'all 0.2s ease',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      color: '#1f2937',
      '&:focus': {
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 0 2px rgba(255, 255, 255, 0.2)'
      }
    },
    startButton: {
      background: 'rgba(255, 255, 255, 0.9)',
      color: '#4F46E5',
      border: 'none',
      padding: '0.75rem 1rem',
      borderRadius: '8px',
      width: '100%',
      fontSize: '1rem',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      '&:hover': {
        backgroundColor: '#ffffff'
      }
    },
    timerCircle: {
      position: 'relative',
      width: '200px',
      height: '200px',
      marginBottom: '1.5rem'
    }as React.CSSProperties,
    progressRing: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'rotate(-90deg)'
    }as React.CSSProperties,
    timeDisplay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '3.5rem',
      fontFamily: 'monospace',
      color: 'white'
      
    }as React.CSSProperties,
    controls: {
      display: 'flex',
      gap: '1.5rem',
      justifyContent: 'center',
      marginTop: '1rem'
    },
    controlButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: '0.5rem',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        transform: 'scale(1.1)'
      }
    },
    stopButton: {
      backgroundColor: '#6366F1',
      color: 'white',
      padding: '0.5rem 1rem',
      borderRadius: '999px',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: '0.875rem',
      fontWeight: '500',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: '#4F46E5'
      }
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: '600',
      color: '#ffffff',
      marginBottom: '1.5rem',
      textAlign: 'center'
    }as React.CSSProperties,
  };

  // Timer logic and handlers remain the same
  useEffect(() => {
    if (!showTimer && customDuration) {
      const newDuration = customDuration * 60;
      setTimeLeft(newDuration);
      setInitialTimeLeft(newDuration);
    }
  }, [customDuration, showTimer]);

  useEffect(() => {
    let interval;
    if (isRunning && timeLeft > 0 && !isPaused) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
      setIsPaused(false);
      onTimerEnd?.();
    }
    return () => clearInterval(interval);
  }, [isRunning, timeLeft, isPaused, onTimerEnd]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const calculateProgress = () => {
    return ((initialTimeLeft - timeLeft) / initialTimeLeft) * 100;
  };

  const getProgressStyle = () => {
    const progress = calculateProgress();
    return {
      background: `conic-gradient(
        #6366F1 ${progress}%,
        rgba(99, 102, 241, 0.2) ${progress}%
      )`
    };
  };

  const handleQuickSelect = (minutes) => {
    const seconds = minutes * 60;
    setTimeLeft(seconds);
    setInitialTimeLeft(seconds);
    setIsRunning(true);
    setShowTimer(true);
    setIsPaused(false);
  };

  const handleDurationChange = (event) => {
    const value = event.target.value;
    if (value === '') {
      setCustomDuration(null);
    } else {
      const numValue = Number(value);
      if (!isNaN(numValue) && numValue > 0) {
        setCustomDuration(numValue);
      }
    }
  };

  const handleStart = () => {
    if (customDuration && customDuration > 0) {
      setIsRunning(true);
      setShowTimer(true);
      setIsPaused(false);
    }
  };

  const handlePause = () => {
    setIsRunning(false);
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsRunning(true);
    setIsPaused(false);
  };

  const handleReset = () => {
    setTimeLeft(initialTimeLeft);
    setIsRunning(false);
    setIsPaused(false);
  };

  const handleStop = () => {
    setShowTimer(false);
    setIsRunning(false);
    setIsPaused(false);
    setTimeLeft(initialTimeLeft);
    setShowCustomInput(false);
  };
  

  if (!showTimer) {
    return (
      <div style={styles.timerContainer}>
        <Button
        color="secondary"
        onClick={() => navigate(-1)}
        style={{ position: 'fixed', bottom: 40, right: 40, zIndex: 1000 }}
      >
        Go Back
      </Button>
        <div style={styles.timerCard}>
          <h2 style={styles.title}>Set Timer Duration</h2>
          <div style={styles.quickSelectContainer}>
            {[1, 2, 3, 4, 5].map((minutes) => (
              <button
                key={minutes}
                onClick={() => handleQuickSelect(minutes)}
                style={styles.quickSelectButton}
              >
                <span style={styles.buttonNumber}>{minutes}</span>
                <span style={styles.buttonUnit}>min</span>
              </button>
            ))}
          </div>

          {!showCustomInput ? (
            <button
              onClick={() => setShowCustomInput(true)}
              style={styles.customButton}
            >
              <Clock size={20} />
              Custom Duration
            </button>
          ) : (
            <div style={{ width: '100%' }}>
              <input
                type="number"
                value={customDuration}
                onChange={handleDurationChange}
                placeholder="Enter minutes"
                min="1"
                style={styles.input}
              />
              <button
                onClick={handleStart}
                style={styles.startButton}
              >
                Start Timer
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={styles.timerContainer}>
      <div style={styles.timerCard}>
        <div style={styles.timerCircle}>
          <div style={{...styles.progressRing, ...getProgressStyle()}} />
          <div style={styles.timeDisplay}>
            {formatTime(timeLeft)}
          </div>
        </div>
        
        <div style={styles.controls}>
          <button 
            onClick={isPaused ? handleResume : handlePause} 
            style={styles.controlButton}
          >
            {isPaused ? (
              <PlayCircle size={32} color="#6366F1" />
            ) : (
              <PauseCircle size={32} color="#6366F1" />
            )}
          </button>
          <button onClick={handleStop} style={styles.stopButton}>
            <Square size={16} /> Stop
          </button>
          <button onClick={handleReset} style={styles.controlButton}>
            <RotateCcw size={32} color="#6366F1" />
          </button>
        </div>
      </div>
<div>

</div>
      
    </div>
  );
};

export default FullScreenTimer;