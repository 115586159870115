import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';


const flip = keyframes`
  0% { transform: rotateY(0); }
  50% { transform: rotateY(180deg); }
  100% { transform: rotateY(360deg); }
`;


const FlipContainer = styled(Box)({
  width: "100%",
  height: "100%",
  position: 'relative',
  transformStyle: 'preserve-3d',
  animation: `${flip} 30s infinite linear`,
});


const ImageWrapper = styled(Box)({
  width: '100%',
  height: '100%',
   borderRadius:'100px',
  position: 'absolute',
  backfaceVisibility: 'hidden',
  '& img': {
    width: '95%',
    height: '95%',
    objectFit: 'contain',
  },
});


const BackImageWrapper = styled(ImageWrapper)({
  transform: 'rotateY(180deg)',
});


const FlipImage = ({ frontImage, backImage }) => {
  return (
    <FlipContainer>
      <ImageWrapper>
        <img src={frontImage} alt="Front" />
      </ImageWrapper>
      <BackImageWrapper>
        <img src={backImage || frontImage} alt="Back" />
      </BackImageWrapper>
    </FlipContainer>
  );
};


export default FlipImage;


// Usage example:
// import FlipImage from './path/to/FlipImage';
// 
// function App() {
//   return (
//     <div className="App">
//       <FlipImage 
//         frontImage="/path/to/Bhashyam-School-App.jpg"
//         backImage="/path/to/Bhashyam-School-App.jpg"
//       />
//     </div>
//   );
// }
