import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Button,
  IconButton,
  Drawer
} from "@mui/material";
import Confetti from "react-confetti";
import "./Quiz.css";
import QuizComponent from "./QuizComponent";
import StudentLeaderboard from "./Leaderboard";
import { useNavigate } from 'react-router-dom'; 
import LessonQRCode from "../../LessonQRCode";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import CloseIcon from "@mui/icons-material/Close";


interface QuizProps {
  plan: any; // You can define a more specific type based on your plan structure
}

const PodiumPlatform: React.FC<{ position: number }> = ({ position }) => (
  <Box
    sx={{
      width: 220,
      height: position === 1 ? 400 : 300,
      backgroundColor: "grey.300",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: -10,
      marginX: 1,
      position: "relative",
    }}
  >
    <Typography variant="h3" sx={{ color: "black", fontWeight: "bold", marginBottom: 1 }}>
      {position}
    </Typography>

    <Typography variant="body1" sx={{ color: "gray", fontWeight: "bold", textAlign: "center" }}>
      {position === 1 ? "13 Questions Answered Correct" : position === 2 ? "10 Questions Answered Correct" : "09 Questions Answered Correct"}
    </Typography>

    <Box
      component="img"
      src={
        position === 1
          ? "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fimages%20(6).jfif?alt=media&token=debbe6e2-26a4-41e1-9cc5-ccde771eb6a2"
          : position === 2
          ? "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fishita-niwas-gn-ii.webp?alt=media&token=52a5e607-a44a-41ae-8c12-d40bc2c7bcb2"
          : "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fdownload%20(5).jfif?alt=media&token=6dc01496-1c8e-44fc-81a4-ec7688085a9b"
      }
      alt={`Position ${position}`}
      sx={{ width: 100, height: 100, borderRadius: "50%", border: "2px solid grey", objectFit: "cover", marginTop: -32 }}
    />

    <Typography variant="h6" sx={{ color: "black", fontWeight: "bold", textAlign: "center", marginTop: 1 }}>
      {position === 1 ? "Abu" : position === 2 ? "Suhasini" : "Jyo"}
    </Typography>
  </Box>
);

const Podium: React.FC = () => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showConfetti && <Confetti />}
      <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "center", height: "auto", position: "relative", paddingTop: 20 }}>
        <PodiumPlatform position={2} />
        <PodiumPlatform position={1} />
        <PodiumPlatform position={3} />
      </Box>
    </>
  );
};


const Quiz: React.FC<QuizProps> = ({ plan }) => {
 
  const [loading, setLoading] = useState(true);
  const [showPodium, setShowPodium] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [isQuizOpen, setIsQuizOpen] = useState(false);
  const navigate = useNavigate(); 

  console.log("plan is .....",plan)
  const isPlanAvailable = plan && plan.data && plan.data.length > 0;

  const handleShowPodium = () => {
    setShowQuiz(false);
    setShowPodium(true);
  };

  const handleQuizClick = () => {
    if (isPlanAvailable) {
      setIsQuizOpen(true);
    }
  };

  if (!isQuizOpen) {
    return (
      <Box
        onClick={handleQuizClick}
       
      >
        <QuizOutlinedIcon
          style={{color: isPlanAvailable ? "rgb(10,10,10)" :  "#ccc", fontSize: "17px", marginBottom: "10px" }}
        />
      </Box>
    );
  }


   return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 1000,
        overflow: "hidden",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Button
        color="secondary"
        onClick={() => {
          setIsQuizOpen(false);
          navigate(-1);
        }}
        style={{ position: 'fixed', bottom: 40, right: 40, zIndex: 1000 }}
      >
        End Quiz
      </Button>
      {!showQuiz && !showPodium && <QuizIntro lesson = {plan} onShowQuiz={() => setShowQuiz(true)} />}
      {showQuiz && !showPodium && <QuizComponent lesson = {plan} onShowPodium={handleShowPodium}  />}
      {showPodium && <StudentLeaderboard lesson = {plan} />}
    </Box>
  );
};

export default Quiz;


const QuizIntro = ({lesson, onShowQuiz }) => {
 

  useEffect(() => {
    const timer = setTimeout(() => {
      onShowQuiz();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onShowQuiz]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: 'white',
        padding: 4,
      }}
    >
      <Box
        component="img"
        src="assets/pop_quiz_logo.png"
        alt="Quiz"
        sx={{
          width: 1000,
          height: 300,
          animation: 'pulse 2s infinite',
          '@keyframes pulse': {
            '0%': {
              transform: 'scale(1)',
            },
            '50%': {
              transform: 'scale(1.1)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
      />

      <LessonQRCode lesson={lesson} />  
    </Box>
  );
};
