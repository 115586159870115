import React, { useCallback, useEffect, useState } from "react";
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  Alert,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import axios from "axios";

const API_URL = "http://192.168.29.224/platform/update-icc-status/";

const ProgressBar = ({
  duration,
  index,
  onComplete,
  blockId,
  status,
  trackId,
  planDay,
  isActive,
  updateICCStatus,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return oldProgress + 100 / (duration * 60);
        });
      }, 1000);
    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [isActive, duration]);

  useEffect(() => {
    if (progress >= 100) {
      updateICCStatus(blockId, status).then((response) => {
        console.log("API Response Data:", response);
        onComplete(index);
      });
    }
  }, [progress, blockId, status, onComplete, index, updateICCStatus]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: "6px",
          borderRadius: "4px",
          backgroundColor: "#c1c1c1",
          flexGrow: 1,
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#90ee90",
          },
        }}
      />
      <Typography variant="caption" sx={{ minWidth: '45px', color: 'text.secondary' }}>
        {duration}min
      </Typography>
    </Box>
  );
};

export const DrawerContent = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeBlockIndex, setActiveBlockIndex] = useState(0);
  const { plan, trackId, planDay } = props;

  const isPlanAvailable = plan && plan.data && plan.data.length > 0;

  const handleDrawerOpen = () => {
    if (isPlanAvailable) {
      setDrawerOpen(true);
      setActiveBlockIndex(0);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setActiveBlockIndex(0);
  };

  const parseJSON = (jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  };

  const handleBlockComplete = useCallback(
    (index) => {
      console.log(`Block ${index} completed`);
      if (index < plan.data.length - 1) {
        setActiveBlockIndex((prevIndex) => prevIndex + 1);
      }
    },
    [plan],
  );

  const updateICCStatus = useCallback(
    async (blockId, status) => {
      const body = {
        icc_tracking_id: trackId,
        staff_id: "873",
        lpbpab_id: blockId,
        status: status,
        icc_day: planDay,
        classroom_id: "4216",
      };

      try {
        const response = await axios.put(API_URL, body);
        console.log("API Response check:", response.data);
        console.log("Full API Response:", response);
        return response.data;
      } catch (error) {
        console.error("Error updating ICC status:", error);
        throw error;
      }
    },
    [trackId, planDay],
  );

  const INSTRUCTIONS_PANEL = () => {
    const { names_dict, data: instructionBlocks } = plan;

    return (
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight:50 , fontSize:16}}>
          Lesson Plan: {names_dict.chapter_name}
        </Typography>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          gutterBottom
          sx={{ fontWeight: 50 }}
        >
          Day {names_dict.icc_day}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Timeline
          sx={{
            [`& .MuiTimelineItem-root:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {instructionBlocks.map((block, index) => {
            const content = parseJSON(block.generated_text);
            if (!content) return null;

            return (
              <TimelineItem key={block.icc_id}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  {index < instructionBlocks.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <ProgressBar
                    duration={content.time}
                    index={index}
                    onComplete={handleBlockComplete}
                    blockId={block.icc_id}
                    status={content.status}
                    trackId={trackId}
                    planDay={planDay}
                    isActive={drawerOpen && index === activeBlockIndex}
                    updateICCStatus={updateICCStatus}
                  />

                  <Typography
                    variant="h6"
                    component="h3"
                    gutterBottom
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontSize: 11,
                      textTransform: "uppercase"
                    }}
                  >
                    {block.lpbpab_name}
                  </Typography>

                  <Typography
                    variant="body2"
                    paragraph
                    style={{ fontSize: "10px" }}
                  >
                    {content.text}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    );
  };

  return (
    <div>
      <Link
        onClick={handleDrawerOpen}
        style={{
          fontSize: "1.2em",
          cursor: isPlanAvailable ? "pointer" : "not-allowed",
          justifyContent: "center",
          display: "block",
        }}
        to={""}
      >
        <DescriptionOutlinedIcon
          style={{ color: isPlanAvailable ? "rgb(10,10,10)" : "#ccc", fontSize: "17px", marginBottom: "10px",opacity: isPlanAvailable ? 1 : 0.5 }}
        />
      </Link>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 600, padding: 2 }} role="presentation">
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {plan ? (
            <INSTRUCTIONS_PANEL />
          ) : (
            <Alert variant="filled" severity="error">
              No lesson plan available. Please create a lesson plan first.
            </Alert>
          )}
        </Box>
      </Drawer>
    </div>
  );
};