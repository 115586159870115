import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Modal,
  Grid,
} from "@mui/material";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";


interface SlideItem {
  staff_lesson_slide_id: number;
  staff_lesson_slide_name: string;
  staff_lesson_slide_type: string;
  staff_lesson_slide_path: string;
}

interface GroupedItems {
  [key: string]: SlideItem[];
}
const GalleryComponent = ({plan}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SlideItem| null>(null);
  const [groupedItems, setGroupedItems] = useState<GroupedItems>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const isPlanAvailable = plan && plan.data && plan.data.length > 0;

  const handleDrawerOpen = async () => {
    if (!isPlanAvailable)return;

    setDrawerOpen(true);
    setIsLoading(true);
    try {
      const response = await axios.get(
       `https://admin.kgtopg.com/staff-lesson-slide-list/?classroom_id=${plan.data[1].classroom_std}&subject_sub_id=${plan.data[1].subject_sub_id}&staff_id=${plan.data[1].staff_id}&lsn_id=${plan.data[1].chapter_id}&academic_year_id=${plan.data[1].institute_academic_year_id}&lpbpab_id=0&day=0`
       // //${plan.data[1].icc_day}`
       // "https://admin.kgtopg.com/staff-lesson-slide-list/?classroom_id=10&subject_sub_id=8&staff_id=776&lsn_id=5913&academic_year_id=1107&lpbpab_id=0&day=1"

      );
      setGroupedItems(groupItemsByType(response.data));
    } catch (err) {
      console.error("Error fetching gallery items:", err);
      setError("Failed to load gallery items");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDrawerClose = () => setDrawerOpen(false);
  const handleModalOpen = (item) => {
    setModalOpen(true);
    setSelectedItem(item);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const groupItemsByType = (items) =>
    items.reduce((acc, item) => {
      acc[item.staff_lesson_slide_type] = [
        ...(acc[item.staff_lesson_slide_type] || []),
        item,
      ];
      return acc;
    }, {});

  const getYoutubeId = (url) => {
    const match = url.match(
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    );
    return match && match[2].length === 11 ? match[2] : null;
  };

  const renderThumbnail = (item) => {
    switch (item.staff_lesson_slide_type) {
      case "video/streaming":
        const youtubeId = getYoutubeId(item.staff_lesson_slide_path);
        return youtubeId ? (
          <img
            src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
            alt="YouTube Thumbnail"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
          />
        ) : (
          <p>Invalid YouTube URL</p>
        );
      case "application/pdf":
        return <PictureAsPdfIcon style={{ fontSize: 60, color: "#f44336" }} />;
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return (
          <img
            src={item.staff_lesson_slide_path}
            alt={item.staff_lesson_slide_name}
            style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
          />
        );
      default:
        return <p>Unsupported media type</p>;
    }
  };

  const renderFullContent = (item) => {
    switch (item.staff_lesson_slide_type) {
      case "video/streaming":
        const youtubeId = getYoutubeId(item.staff_lesson_slide_path);
        return youtubeId ? (
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${youtubeId}`}
            title={item.staff_lesson_slide_name}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <p>Invalid YouTube URL</p>
        );
      case "application/pdf":
        return (
          <object
            data={item.staff_lesson_slide_path}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>
              It appears you don't have a PDF plugin for this browser. You can{" "}
              <a href={item.staff_lesson_slide_path}>
                click here to download the PDF file.
              </a>
            </p>
          </object>
        );
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return (
          <img
            src={item.staff_lesson_slide_path}
            alt={item.staff_lesson_slide_name}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        );
      default:
        return <p>Unsupported media type: {item.staff_lesson_slide_type}</p>;
    }
  };

  return (
    <div>
      <Box
        onClick={handleDrawerOpen}
        style={{
          fontSize: "1.2em",
          cursor: isPlanAvailable ? "pointer" : "not-allowed",
          justifyContent: "center",
          display: "block",
        }}
      >
        <CollectionsOutlinedIcon
          style={{ color: isPlanAvailable ? "rgb(10,10,10)" : "#ccc", fontSize: "17px", marginBottom: "10px",opacity: isPlanAvailable ? 1 : 0.5 }}
        />
      </Box>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 400, padding: 2 }} role="presentation">
          <IconButton onClick={handleDrawerClose} style={{ float: "right" }}>
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            style={{
              color: "#1971c2",
              marginBottom: 5,
              marginTop: 5,
              alignItems: "center",
              fontWeight: "bold",
              justifyContent: "center",
              display: "flex",
            }}
          >
            Gallery
          </Typography>

          {isLoading && <p>Loading...</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
          {!isLoading &&
            !error &&
            Object.entries(groupedItems).map(([type, items]) => (
              <div key={type}>
                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>
                  {type === "video/streaming"
                    ? "Videos"
                    : type === "application/pdf"
                    ? "PDFs"
                    : type.startsWith("image/")
                    ? "Images"
                    : type}
                </Typography>
                <Grid container spacing={2}>
                  {items.map((item) => (
                    <Grid item xs={4} key={item.staff_lesson_slide_id}>
                      <Box
                        onClick={() => handleModalOpen(item)}
                        sx={{
                          cursor: "pointer",
                          textAlign: "center",
                          "&:hover": { opacity: 0.7 },
                        }}
                      >
                        {renderThumbnail(item)}
                        <Typography variant="caption" display="block">
                          {item.staff_lesson_slide_name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
        </Box>
      </Drawer>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95vw",
            height: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton onClick={handleModalClose} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          {selectedItem && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {renderFullContent(selectedItem)}
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default GalleryComponent;
