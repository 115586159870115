/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable curly */
/* eslint-disable prefer-template */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, { Component, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Dashboard.css"; // For custom styling
import Card from "@mui/material/Card";
// import div from "@mui/material/div";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

// classrooms select dropdown
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import type { SnackbarCloseReason } from "@mui/material";
import {
  Alert,
  Button,
  Typography,
  Container,
  Avatar,
  Snackbar,
  AlertTitle,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid2 from "@mui/material/Grid2";
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

// import { withRouter } from './withRouter'; // Assuming the HOC is in withRouter.tsx

// vidya bot
import { MessageCircle, X } from "lucide-react";
import Vidya from "./frags/Vidya"; // Assuming SiderChatGPT is in the same directory

import DASHBOARDAPI from "./http/dashboardapi";
import { timers } from "jquery";
import { withRouter } from "./withRouter";
import Constants from "./util/Constants";
import FlipImage from "./frags/FlipLogo";
import Modal from '@mui/material/Modal';
import  LessonQRCode  from './LessonQRCode';

const teachers = [
  { name: "Vijay Kumar", period: "P2", subject: "Science" },
  { name: "Vijay Kumar", period: "P3", subject: "Science" },
  { name: "Vijay Kumar", period: "P4", subject: "Science" },
  { name: "Vijay Kumar", period: "P5", subject: "Science" },
  { name: "Vijay Kumar", period: "P5", subject: "Science" },
  { name: "Vijay Kumar", period: "P5", subject: "Science" },
];

// stored value
const oClrId = localStorage.getItem("co.vidya.clrid") || 0;

function getCurrentTime() {
  const now = new Date();

  const hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "P.M" : "A.M";

  const formattedTime = `${hours % 12 || 12}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = daysOfWeek[now.getDay()];
  const day = now.getDate();
  const month = now.toLocaleString("en-US", { month: "long" });
  const formattedDate = `${dayOfWeek}, ${day} ${month}`;

  return { formattedTime, formattedDate };
}

const VidyaButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Open Vidya</Button>
      <Vidya open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

const ClassroomDropdown = (props: any) => {
  const [age, setAge] = React.useState("");
  const [clrId, setClrId] = React.useState(oClrId); // default from storage
  const { classrooms } = props;

  const handleChange = (event: SelectChangeEvent) => {
    // setAge(event.target.value);
    const clrId = event.target.value;
    // const key = event.target.getAttribute("key");
    console.log("ClassroomDropdown classrooms", classrooms);

    // const clr = classrooms[key];

    // update local-storage
    localStorage.setItem("co.vidya.clrid", clrId);
    console.log("ClassroomDropdown set clrId:", clrId);

    props.handleChange(clrId);
    setClrId(clrId);
  };

  console.log("ClassroomDropdown oClrId: " + oClrId);
  return (
    <FormControl sx={{ m: 1, width: 220 }} size="small">
      {/* <InputLabel id="demo-select-small-label">Choose Classroom</InputLabel> */}
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={clrId}
        label="Choose Classroom"
        onChange={handleChange}
        sx={{
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiSelect-icon": {
            display: "none",
          },
          // Increase font size for the selected item and dropdown menu
          "& .MuiSelect-select": {
            fontSize: "1.2rem", // Adjust the font size as needed
          },
          "& .MuiMenuItem-root": {
            fontSize: "1.2rem", // Adjust the font size for menu items
          },
        }} // no border and icon
      >
        <MenuItem key="-1" value={0} selected={true}>
          Choose Classroom
        </MenuItem>
        {classrooms && classrooms.data && classrooms.data.length ? (
          classrooms.data.map((classroom, idx) => {
            let sel = false;

            console.log(
              "ClassroomDropdown classroom_id",
              classroom.classroom_id,
            );

            // see if already selected
            if (
              oClrId &&
              parseInt(oClrId) === parseInt(classroom.classroom_id)
            ) {
              sel = true;

              console.log("ClassroomDropdown locked");
            }

            console.log("ClassroomDropdown sel", sel);
            return (
              <MenuItem
                key={idx}
                id={classroom.classroom_id}
                value={classroom.classroom_id}
                selected={sel}
              >
                Classroom {classroom.classroom_name}{" "}
                {classroom.classroom_section}
              </MenuItem>
            );
            // return <MenuItem key={idx} id={classroom.classroom_id} value={classroom.classroom_id}>Classroom {classroom.classroom_name} {classroom.classroom_section}</MenuItem>;
          })
        ) : (
          <></>
        )}
      </Select>
    </FormControl>
  );
};


const LESSON = (props: any) => {
  const { lesson, index } = props;
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [showQR, setShowQR] = useState(false);

  const {
    staff_name,
    timing_index,
    lesson_start_time,
    lesson_end_time,
    subject_sub_name,
    class_name,
    current_class,
  } = lesson;

  const handleLessonChange = (lsnId) => {
    props.handleLessonChange(lsnId);
  };

  const toggleQR = () => {
    setShowQR(!showQR);
  };
 

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        maxWidth: 290, // Set a max-width instead of fixed width
        height: 90,// Fixed height for all cards
        display: "flex",
        alignItems: "center",
        p: 2,
        bgcolor: selectedLessonId === lesson.lesson_id ? 'lightblue' : '',
        cursor: "pointer",
        '&:hover': {
          bgcolor: 'rgba(0, 0, 0, 0.04)'
        }
      }}
      onClick={() => handleLessonChange(lesson.lesson_id)}
    >
      {/* Avatar section */}
      {lesson.staff_photo ? (
        <Avatar
          alt={lesson.staff_name}
          src={lesson.staff_photo}
          sx={{
            width: 60,
            height: 60,
            mr: 2,
            bgcolor: `hsl(${index * 60}, 70%, 80%)`
          }}
        />
      ) : (
        <Avatar
          sx={{
            width: 60,
            height: 60,
            mr: 2,
            fontSize: '2rem',
            bgcolor: `hsl(${index * 60}, 70%, 80%)`
          }}
        >
          {lesson.staff_name?.charAt(0) || 'T'}
        </Avatar>
      )}

      {/* Content section */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: "bold",
            fontSize: '0.7rem',
            mb: 0.5
          }}
        >
          P{lesson.timing_index + 1} - {lesson.subject_sub_name}
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'text.secondary',
            fontSize: '0.7rem'
          }}
        >
          {lesson.staff_name}
        </Typography>
        {lesson.chapter && (
            <Typography variant="body2" color="textSecondary">
              {lesson.chapter.pub_chap_name}
            </Typography>
          )}
      </Box>
    </Paper>
  );
};

const ResponsiveAlert = ({ variant,  message }) => {
  return (
    <Alert
      variant={variant}
      icon={<WarningIcon />}
      sx={{
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F15A24',
        color: 'white',
        borderRadius: '4px',
        '& .MuiAlert-icon': {
          color: 'white',
          marginRight: 1
        },
        '& .MuiAlert-message': {
          padding: '8px 0',
          fontSize: '0.875rem'
        }
      }}
    >
      {message}
    </Alert>
  );
};


const LESSONS = (props: any) => {
  const { lessons } = props;
  const [selectedLessonId, setSelectedLessonId] = useState(null);


  return (
      <Grid2 
        container 
        spacing={3} 
        sx={{ 
          p: 3,
          width: '100%',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          '@media (min-width: 600px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        }}
      >
        {lessons?.data?.today?.length ? (
          lessons.data.today.map((lesson, index) => (
            <Grid2 
              item 
              xs={12} 
              sm={6} 
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
            <LESSON
              lesson={lesson}
              index={index}
              handleLessonChange={(lsnId) => {
                props.handleLessonChange(lsnId);
                setSelectedLessonId(lsnId);
              }}
            />  
          </Grid2>
        ))
        
      ) : (
        <Grid2 item xs={12}>
        <ResponsiveAlert
    variant="filled"
    message="No classes scheduled. Please check the timetable for upcoming lessons."
  />
      </Grid2>
      )}
    </Grid2> 
  );
};
// const LESSONS = (props: any) => {
//   const { lessons } = props;

//   const [selectedLessonId, setSelectedLessonId] = useState(null);

//   return (
//     <>
//       {lessons &&
//         lessons.data &&
//         lessons.data.today &&
//         lessons.data.today.length ? (
//         lessons.data.today.map((lesson, index) => {
//           return (
//             <Grid2 item xs={12} sm={6} md={6} lg={6} xl={6} key={index} width="50%">
//               <Paper
//                 sx={{
//                   width: "100%",
//                   height: "100px",
//                   background: "none",
//                   border: 0,
//                   boxShadow: "none",
//                   display: 'flex',
//                   alignItems: 'center', // Align items to the top
//                   justifyContent: 'flex-start',
//                   overflow: 'hidden',
//                   padding: '5%',
//                   backgroundColor: selectedLessonId === lesson.lesson_id ? 'lightblue' : 'transparent',
//                 }}
//               >
//                 <Grid2 container spacing={0}
//                 sx={{
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//                 >
//                   <LESSON
//                     lesson={lesson}
//                     index={index}
//                     handleLessonChange={(lsnId) => {
//                       props.handleLessonChange(lsnId);
//                       setSelectedLessonId(lsnId);
//                     }}
//                   />
//                 </Grid2>
//               </Paper>
//             </Grid2>

//           );
//         })
//       ) : (
//         <div
//         style={{ width: '90%', mb: 2, textAlign: 'center', margin: 'auto' }}>
//           <Alert
//             variant="filled"
//             severity="warning"
//           >
//             No classes are scheduled for today. Please check the timetable for upcoming lessons.
//           </Alert>

//         </div>
//       )}
//     </>
//   );
// };

// here is function for choose teacher sk
const ChooseTeacherButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [openSnackbar, setOpenSnackbar] = React.useState(false); // Snackbar state sk

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

   // Function to handle snackbar close sk
   const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  // Function to handle the button click sk
  const handleButtonClick = () => {
    props.onButtonClicked(); // Call the parent's click handler
    setOpenSnackbar(true); // Open the snackbar
  };

  return (
    <>
    <ButtonGroup
      variant="contained"
      ref={anchorRef}
      aria-label="Button group with a nested menu"
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        // sx={{ mt: 1 }}
        // onClick={() => {
        //   this.setState((prevState) => ({
        //     showInstLogo: !prevState.showInstLogo, // flip
        //   }));
        // }}
        disabled={true}
      >
        Choose Teacher
      </Button>
      <Button
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="Choose Teacher"
        // updated onclick sk
         onClick={handleButtonClick}
      >
        {/* <ArrowDropDownIcon /> */}
        <ArrowRightIcon fontSize="small" />
      </Button>
    </ButtonGroup>

    <Snackbar
    open={openSnackbar}
    autoHideDuration={3000} // Close after 3 seconds
    onClose={handleCloseSnackbar}
    message="Starting the Class. Please wait..."
    />
    </>
  )
}

const CurrentTeacherButton = (props) => {
  const { currLesson } = props;

  return (
    <Box>
      <Typography variant="h6" sx={{ 
            fontWeight: "bold",
            fontSize: '0.9rem',
          }}>{currLesson.staff_name}</Typography>
      <Typography variant="body1" 
          sx={{ 
            color: 'text.secondary',
            fontSize: '0.8rem',
            mb: 0.5
          }}>
        P{currLesson.timing_index + 1} - {currLesson.subject_sub_name}
        {currLesson.chapter && `, ${currLesson.chapter.pub_chap_name}`}
      </Typography>
      <ButtonGroup
        variant="contained"
        aria-label="Button group with a nested menu"
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => props.handleLessonChange(currLesson.lesson_id)}
        >
          START NOW
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Choose Teacher"
          // aria-haspopup="menu"
          onClick={() => {
            props.onButtonClicked();
          }}
        >
          {/* <ArrowDropDownIcon /> */}
          <ArrowRightIcon fontSize="small" />
        </Button>
        {/* 

        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mt: 1 }}
          onClick={() => props.handleLessonChange(currLesson.lesson_id)}
        >
          START NOW
        </Button> */}
      </ButtonGroup>
    </Box>
  )
}

class Dashboard extends Component {

 

  constructor(props: any) {
    super(props);
    this.state = {
      classrooms: [],
      clrId: 0,
      clsStd: 0,
      classroom: null,
      lessons: [],
      currLesson: null,
      instId: Constants.INSTITUTE_ID, //local constant
      staffId: 0,
      pubId: 0,
      yearId: Constants.YEAR_ID,
      instLogo: '',
      oPlanId: 0,
      time: getCurrentTime(),
      snackOpen: false,
      showInstLogo: true,
      // isFlipped: false,
    };
  }

  

  // Method to handle image flip
  // toggleFlip = () => {
  //   this.setState((prevState) => ({
  //     isFlipped: !prevState.isFlipped,
  //   }));
  // };

  // open snackbar
  handleClick = () => {
    this.setState({ snackOpen: true });
  };

  // close snackbar
  handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackOpen: false });
  };

  tick() {
    this.setState({ time: getCurrentTime() });
  }

  // here is onButton for dashboard image @suha
  onButtonClicked() {
    this.setState((prevState) => ({
      showInstLogo: !prevState.showInstLogo, // flip
    }));
    
  }

  findClassroom(clrId) {
    const { classrooms } = this.state;
    console.log("findClassroom clrId", clrId);
    console.log("findClassroom classrooms", classrooms);
    // Update state (assuming you have a state variable for selected classroom)
    const clr = classrooms.data.find((c) => {
      console.log("c clrId", c.classroom_id);
      console.log("clrId", clrId);
      return parseInt(c.classroom_id) === parseInt(clrId);
    });

    console.log("findClassroom clr", clr);

    if (clr) this.updateClassroom(clr); // setup everything
  }

  async onTeacherClicked() {
    const { currLesson, clsStd } = this.state;

    let chpId = 0;
    let topId = 0;
    const staffId = currLesson.staff_id;
    const subId = currLesson.subject_id;
    const subSubId = currLesson.subject_sub_id;

    const cCLsnId = currLesson.lesson_id;
    let stfLsnId = 0;
    let goVar = false;
    let isLive = false;

    // staff-lesson is
    // current-class is if any class is currently active at this moment
    const { staff_lesson, current_class } = currLesson;

    if (staff_lesson) {
      const { staff_lesson_id, chapter_id, topic_id } = staff_lesson;
      stfLsnId = staff_lesson !== null ? staff_lesson.staff_lesson_id : 0;

      goVar = current_class;
      isLive = staff_lesson !== null && staff_lesson.staff_lesson_is_live === 1;

      chpId = chapter_id;
      topId = topic_id;
    }

    // start the class
    this.getPublisher(
      goVar,
      isLive,
      cCLsnId,
      stfLsnId,
      chpId,
      topId,
      staffId,
      subId,
      subSubId,
      clsStd,
    );
  }

  handleLessonChange = (lsnId) => {
    const { lessons } = this.state;
    // Update state (assuming you have a state variable for selected classroom)
    const lsn = lessons.data.today.find(
      (l) => parseInt(l.lesson_id) === parseInt(lsnId),
    );

    this.setState({ currLesson: lsn }, () => {
      // now call the create/join meeting method
      this.onTeacherClicked();
    });
  };

  // get publisher associate with the institute/class/subject
  async getPublisher(
    goVar: any,
    isLive: any,
    cCLsnId: any,
    stfLsnId: any,
    chpId: any,
    topId: any,
    staffId: any,
    subId: any,
    subSubId: any,
    clsStd: any,
  ) {
    const { instId, yearId } = this.state;
    const pubId = await DASHBOARDAPI.getPublisher(
      subId,
      subSubId,
      clsStd,
      instId,
      yearId,
    );
    console.log("getPublisher publisher", pubId);

    this.setState({ pubId }, async () => {
      const { classroom_id } = this.state.classroom;
      const { currLesson } = this.state;

      // using publisher, we are trying to get the current lesson plan
      const currentLessonPlan = await DASHBOARDAPI.getCurrentLesson2(
        staffId,
        classroom_id,
        subId,
        subSubId,
        instId,
        pubId,
      );
      console.log("getPublisher currentLessonPlan", currentLessonPlan);
      console.log('getPublisher currLesson', currLesson);

      let chapId = 0;
      let chapName = "";
      let iccDay = 0;
      
      if (currentLessonPlan) {
        // setup the plan-id (chapter to be taught)
        const { data, names_dict } = currentLessonPlan;
        try {
          const { chapter_id, chapter_name, icc_day } = names_dict;
          chapId = chapter_id;
          chapName = chapter_name;
          iccDay = icc_day;

        } catch (e) {
          console.error("currentLessonPlan exception ", e);
          chapId = 0;
        }

        this.setState({ oPlanId: chapId }, async () => {
          // setup the live
          const { oPlanId, clrId } = this.state;
          const { subject_name, subject_sub_name, staff_name } = currLesson;
          const callLiveClass = await DASHBOARDAPI.postTeacherCreateMeeting(
            goVar,
            isLive,
            cCLsnId,
            stfLsnId,
            chpId,
            topId,
            staffId,
            subId,
            subSubId,
            clsStd,
            instId,
            classroom_id,
            oPlanId,
            
          );
          console.log("callLiveClass", callLiveClass);

          // update variables for start | end lesson api call sk
          let sLsnId = 0;
          if(callLiveClass && callLiveClass.message) {
            sLsnId = callLiveClass.data.staff_lesson.staff_lesson_id;
          }
          console.log("calling staff_lesson_id", sLsnId);

          // navigate to whiteboard page
          const postState = {
            staffId,
            subId,
            subSubId,
            clsStd,
            instId,
            clrId,
            oPlanId,
            yearId,
            chapName: chapName || "No Chapter",
            subject_name,
            subject_sub_name,
            staff_name,
            sLsnId
          };

          // save to local storage
          localStorage.setItem(Constants.KEY_WHITEBOARD_PARAMS, JSON.stringify(postState));

          this.props.router.navigate("/whiteboard", {
            state: postState,
          });
        });
      }
    });
  }

  getInstClassrooms = async () => {
    const { instId } = this.state;
    const classrooms = await DASHBOARDAPI.getDefaultClassrooms(instId);
    console.log("getInstClassrooms classrooms", classrooms);

    // update the state
    this.setState({ classrooms }, () => {
      this.findClassroom(oClrId);
    });
  };

  fetchClassroomLessons = async (clrId: any) => {
    const lessons = await DASHBOARDAPI.getClassroomLessons(clrId);
    console.log("fetchClassroomLessons lessons", lessons);

    // decide if the class is currently running
    if (lessons && lessons.data && lessons.data.today) {
      lessons.data.today.forEach((lsn) => {
        const { current_class } = lsn;

        if (current_class) {
          this.setState({ currLesson: lsn }, () => {
            console.log(
              "fetchClassroomLessons currLesson",
              this.state.currLesson,
            );
          });
        }
      });
    }
    this.setState({ lessons });
  };

  handleChange = (clrId: string) => {
    console.log("handleChange clrId", clrId);
    this.setState({ clrId });

    this.fetchClassroomLessons(clrId);
    this.findClassroom(clrId); // to update the vars
  };

  updateClassroom = (clr: any) => {
    const { classroom_id, classroom_std } = clr;
    this.setState({
      classroom: clr,
      clrId: classroom_id,
      clsStd: classroom_std,
    });
  };

  componentDidMount() {

    // update institute fields
    this.setState({
      instLogo: localStorage.getItem("board.classess.institute_logo") ?? '',
      instId: localStorage.getItem("board.classess.institute_id") ?? 0,
      yearId: localStorage.getItem("board.classess.institute_academic_year_id") ?? 0,
    }, () => {

      console.log('componentDidMount instId after setState from localStorage', this.state.instId);
      this.getInstClassrooms();

      // see if already selected
      if (oClrId) {
        this.fetchClassroomLessons(oClrId);
      }

    });

  }

  render() {
    const { classrooms, clrId, lessons, classroom, currLesson, snackOpen } =
      this.state;
    const currentTime = getCurrentTime();

    const { formattedTime, formattedDate } = this.state.time;

    const { isFlipped } = this.state;

    console.log("Dashboard render currLesson", currLesson);
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            py: 3,
            width: "80%",
          }}
          style={{
            width: '50%'
          }}
        >
          {/* <Typography variant="h4" align="center" sx={{ mb: 4 }}>
            Class - 9 A
          </Typography> */}
          <div align="center">
            <ClassroomDropdown
              classrooms={classrooms}
              handleChange={(clrId: any) => this.handleChange(clrId)}
              updateClassroom={(clr: any) => this.updateClassroom(clr)}
            />
          </div>

          {/* Teachers Grid */}

          {!this.state.showInstLogo ? (
            <>

              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "#FFF8F0",
                  position: 'relative'
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10
                  }}>
                  <Button
                    onClick={() => this.onButtonClicked()}
                  >
                    <CloseIcon
                      sx={{
                        color: '#FF4500',
                      }}
                    />
                  </Button>
                </div>
                


                {/* LESSONS BOX */}
                <Grid2
                  container
                  spacing={0}
                  sx={{
                    width: "95%",
                    height: '70vh',
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    },
                  }}
                >
                  <LESSONS
                    lessons={lessons}
                    handleLessonChange={(lsnId) => this.handleLessonChange(lsnId)}
                    onButtonClicked={() => this.onButtonClicked()}
                  />
                </Grid2>
              </Box>
            </>
          ) : (
            <>
              {/* institute logo */}

              <div
                onClick={this.toggleFlip} // Add click event to flip the image
                style={{
                  height: "70vh",
                  width: "100%",
                  // backgroundImage: 'url("../assets/schoollogo.jpeg")',
                  //backgroundImage: 'url("' + this.state.instLogo + '")',
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  // added effect for flip img suha
                  // backgroundImage: `url("${isFlipped ? this.state.backLogo : this.state.instLogo}")`,
                  // cursor: "pointer", // Optional: makes the image look clickable
                  // transition: "transform 0.6s", // Smooth transition
                  // transform: isFlipped ? "rotateY(180deg)" : "none", // Flip effect
                }}
              >
                <FlipImage 
                frontImage={this.state.instLogo}
                backImage={this.state.instLogo}
                />
              </div>
            </>
          )}

          {/* <img src=""  /> */}
        </Container>

        {/* Bottom Left - Single Teacher */}
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            left: 56,
            maxWidth: 400,
            width: 300,
          }}
        >
          {currLesson ? (
            <Paper
              elevation={3}
              sx={{ p: 2, display: "flex", justifyContent: 'space-around', alignItems: "center" }}
            >
              {/* <Avatar
              src="/path-to-teacher-image.jpg"
              sx={{ width: 80, height: 80, mr: 2 }}
            /> */}
              {currLesson.staff_photo ? (
                <Avatar
                  alt={currLesson.staff_name}
                  src={currLesson.staff_photo}
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: `hsl(${0 * 60}, 70%, 80%)`,
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: `hsl(${0 * 60}, 70%, 80%)`,
                  }}
                />
              )}
              <div>
                <CurrentTeacherButton
                  currLesson={currLesson}
                  handleLessonChange={(lessonId) => this.handleLessonChange(lessonId)}
                  onButtonClicked={() => this.onButtonClicked()}
                />
              </div>
            </Paper>
          ) : (
            <>
              <ChooseTeacherButton
                onButtonClicked={() => this.onButtonClicked()}
              />
            </>
          )}
        </Box>

        {/* Bottom Right - Clock */}
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            right: 56,
            textAlign: "right",
          }}
        >
          <Typography variant="h3">{formattedTime}</Typography>
          <Typography variant="h5">{formattedDate}</Typography>
        </Box>

        {/* Top Left - Logo */}
        <Box
          sx={{
            position: "absolute",
            top: 20,
            left: 20,
          }}
        >
          <img
            width={150}
            src="https://classess.com/schooler/examer/assets/Classesslogo.png"
            height={50}
          />
        </Box>

        {/* snackbar message */}
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message="Oops, there's no Lesson Plan found for you!"
        />
      </Box>
    );
  }
}

//Dashboard.contextType = LessonContext;

// Use `withRouter` to inject the routing props
export default withRouter(Dashboard);
