import React, { useState, useEffect } from 'react';
import { Trophy, Medal, Loader } from 'lucide-react';
import './Leaderboard.css'; 

interface Student {
  student_id: number;
  student_name: string;
  student_photo: string;
  student_total_marks_secured: number;
  student_position: number;
}


interface StudentLeaderboardProps
{
  lesson : any;
}

const StudentLeaderboard: React.FC<StudentLeaderboardProps> = ({ lesson }) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const defaultPhoto = 'https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fnoprofile.jpg?alt=media&token=fbfd5c17-1932-4618-a0a7-4c44acf3a7a7';

  let chapterId = lesson.data[0].chapter_id
  let classrommId = lesson.data[0].icc_tracking.classroom_id
  let day = lesson.data[0].icc_day
  let subject_id = lesson.data[0].subject_id
  let subject_sub_id = lesson.data[0].subject_sub_id
  let staff_lesson_id = lesson.data[0].chapter_id
  let institute_academic_year_id =  lesson.data[0].institute_academic_year_id
  let classroom_id = lesson.data[0].icc_tracking.classroom_id

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = async () => {
    try {
      const response = await fetch('https://admin.kgtopg.com/quiz-student-marks/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subject_id: subject_id,
          subject_sub_id: subject_sub_id,
          staff_lesson_id: staff_lesson_id,
          institute_academic_year_id: institute_academic_year_id,
          classroom_id: classroom_id
        })
      });
console.log("leaderboard response",response);
      const result = await response.json();
      const sortedStudents = result.data.sort((a: Student, b: Student) => 
        a.student_position - b.student_position
      );
      setStudents(sortedStudents);
      setLoading(false);
    } catch (err) {
      setError('Failed to load leaderboard data');
      setLoading(false);
    }
  };

  const cleanPhotoUrl = (url: string) => {
    if (!url) return defaultPhoto;
    return url.replace(/url\(['"]?(.*?)['"]?\)/g, '$1');
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        {error}
      </div>
    );
  }

  const groupedStudents = students.reduce((acc, student) => {
    if (!acc[student.student_position]) {
      acc[student.student_position] = [];
    }
    acc[student.student_position].push(student);
    return acc;
  }, {} as Record<number, Student[]>);

  return (
    <div className="leaderboard-container">
      <h2 className="leaderboard-title">
        Student Leaderboard
      </h2>
      
      {Object.entries(groupedStudents).map(([position, positionStudents]) => (
        <div 
          key={position} 
          className={`position-group position-${position}`}
        >
          <div className="position-content">
            <div className="position-header">
              <Trophy className="trophy-icon" />
              <span className="position-title">
                Position {position}
              </span>
            </div>
            
            <div className="students-grid">
              {positionStudents.map((student) => (
                <div
                  key={student.student_id}
                  className="student-card"
                >
                  <div className="student-photo">
                    <img
                      src={cleanPhotoUrl(student.student_photo)}
                      alt={student.student_name}
                      onError={(e) => {
                        (e.target as HTMLImageElement).src = defaultPhoto;
                      }}
                    />
                  </div>
                  {/* <div className="student-info">
                    <span className="student-name">{student.student_name}</span>
                    <span className="student-score">
                      {student.student_total_marks_secured} pts
                    </span>
                  </div> */}
                  {/* {parseInt(position) <= 3 && <Medal className="medal-icon" />} */}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StudentLeaderboard;