import React, { useState, useEffect } from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import "chart.js/auto";
import "./QuizComponent.css";


const OPTION_DELAY = 5000;
const QUIZ_TIME = 30;

interface QuizComponentProps {
  onQuizComplete?: () => void;
  onShowPodium?: () => void;
  lesson : any;
}

interface QuizResult {
  data: {
    students: {
      selected_option: any;
      student_id: number;
      student_name: string;
      student_image: string;
      result: string;
    }[];
    correct: string[];
    question: {
      quiz_question_id: number;
      quiz_id: number;
      question_text: string;
    };
  };
}

interface QuizOption {
  quiz_question_option_id: number;
  quiz_question_id: number;
  question_option_text: string;
  question_option_url: string;
  question_option_index: number;
  question_option_correct: number;
  created_date: string;
  timestamp: string;
}

interface Quiz {
  quiz_question_id: number;
  quiz_id: number;
  question_text: string;
  question_url: string;
  question_topic_text: string;
  question_pts: number;
  question_status: number;
  question_time: string;
  student_attmp: number;
  student_correct: number;
  created_date: string;
  timestamp: string;
  staff_lesson_id: number;
  staff_id: number;
  classroom_id: number;
  subject_id: number;
  subject_sub_id: number;
  chapter_id: number;
  quiz_title: string;
  scheduled_date: string;
  quiz_pub: number;
  quiz_ended: number;
}

interface QuizData {
  quiz: Quiz;
  options: QuizOption[];
}

const QuizComponent: React.FC<QuizComponentProps> = ({ lesson , onShowPodium }) => {
  
  
  const sortOptionsByIndex = (options: QuizOption[]) => {
    return [...options].sort((a, b) => a.question_option_index - b.question_option_index);
  };


  const [isFirstQuestion, setIsFirstQuestion] = useState(true);
  const [quizData, setQuizData] = useState<QuizData[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showOptions, setShowOptions] = useState(true);
  const [showGraph, setShowGraph] = useState(false);
  const [showWaitingAnimation, setShowWaitingAnimation] = useState(false);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(null);
  const [timeLeft, setTimeLeft] = useState(QUIZ_TIME);
  const [questionVisible, setQuestionVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [correctOptionText, setCorrectOptionText] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(
    null,
  );
  const [quizResults, setQuizResults] = useState<QuizResult | null>(null);
  const [timerActive, setTimerActive] = useState(false);
  const [quizSequenceStarted, setQuizSequenceStarted] = useState(false);
  const [isWaitingForRecentQuestion, setIsWaitingForRecentQuestion] = useState(true)
  
  let chapterId = lesson.data[0].chapter_id
  let classrommId = lesson.data[0].icc_tracking.classroom_id
  let day = lesson.data[0].icc_day

  const isWithinFourMinutes = (timestamp: string): boolean => {
    try {
      // Convert current time to IST
      const currentTime = new Date();
      const currentIST = new Date(currentTime.getTime() + (330 * 60000)); // Add 5:30 hours (330 minutes)
      
      // Convert question time to IST
      const questionTime = new Date(timestamp);
      const questionIST = new Date(questionTime.getTime()); // Add 5:30 hours
      
      if (isNaN(currentIST.getTime()) || isNaN(questionIST.getTime())) {
        console.error('Invalid date format');
        return false;
      }
      
      // Get the time difference in minutes
      const differenceInMinutes = (questionIST.getTime() - currentIST.getTime()) / (1000 * 60);
      
      // Log for debugging (show IST times)
      console.log('Current time (IST):', currentIST.toISOString());
      console.log('Question time (IST):', questionIST.toISOString());
      console.log('Time difference (minutes):', differenceInMinutes);
      
      // If the question time is within the next 4 minutes or up to 1 minute in the past
      return differenceInMinutes >= -1 && differenceInMinutes <= 4;
    } catch (error) {
      console.error('Error in isWithinFourMinutes:', error);
      return false;
    }
  };
  

  useEffect(() => {
    const eventSource = new EventSource(
      `https://quiz.kgtopg.com/get-latest-quiz-sse-day/${chapterId}/${classrommId}/${day}`,
    );
   
eventSource.onmessage = (event) => {
  try {
    const parsedData = JSON.parse(event.data);

    if (!parsedData || parsedData.quiz === null || parsedData.options === null) {
      console.log('Quiz has ended, showing leaderboard');
      onShowPodium();
      eventSource.close();
      return;
    }

      const questionTimestamp = parsedData.quiz.timestamp;
      const newQuestionId = parsedData.quiz.quiz_question_id;
      
      console.log('Received question timestamp:', questionTimestamp);

      // Check if question is within time window
      
      if (parsedData.quiz.question_time === "00:00:00") {
        console.log('Question time is 00:00:00, showing graph immediately');
        if (showWaitingAnimation) {
          setShowWaitingAnimation(false);
          setShowGraph(true);
          // No need to fetch results again as the useEffect will handle it
          return;
        }
      }

      else
      {
      if (isWithinFourMinutes(questionTimestamp)) {
        console.log('Question is within time window, displaying it');
        
        if (isWaitingForRecentQuestion) {
          setIsWaitingForRecentQuestion(false);
        }

        setQuizData((prevData) => {
          const exists = prevData.some(q => q.quiz.quiz_question_id === newQuestionId);
          if (!exists) {
            if (currentQuestionId !== null && newQuestionId !== currentQuestionId) {
              setCurrentQuestionIndex(prevData.length);
            }
            return [...prevData, parsedData];
          }
          return prevData;
        });

        
        setCurrentQuestionId(newQuestionId);
        setLoading(false);
        
        if (!quizSequenceStarted) {
          startQuestionSequence();
          setQuizSequenceStarted(true);
        } else if (showGraph) {
          setShowGraph(false);
          setShowWaitingAnimation(false);
          startQuestionSequence();
        }
      } 
        console.log('Question is outside the time window, waiting for recent questions...');
    }
  } catch (error) {
    console.error("Error parsing SSE data:", error);
  }
};

    eventSource.onerror = (error) => {
      console.error("SSE Error:", error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [currentQuestionId, showWaitingAnimation, isFirstQuestion]);

  const startQuestionSequence = () => {
    setShowOptions(false);
    setShowGraph(false);
    setShowWaitingAnimation(false);
    setTimeLeft(QUIZ_TIME);
    setTimerActive(false);
    setQuestionVisible(true);
    setTimeout(() => {
      setShowOptions(true);
      setTimerActive(true);
    }, OPTION_DELAY);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (timerActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            if (timer) clearInterval(timer);
            setTimerActive(false);
            setShowWaitingAnimation(true);
            setShowOptions(false);
            setQuestionVisible(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timerActive, timeLeft]);

  const optionLetters = ["A", "B", "C", "D"];
  useEffect(() => {
    if (quizData[currentQuestionIndex]?.options) {
      const sortedOptions = sortOptionsByIndex(quizData[currentQuestionIndex].options);
      const correctOption = sortedOptions.find((option) => option.question_option_correct === 1);
      if (correctOption) {
        setCorrectOptionIndex(correctOption.question_option_index);
        const optionLetter = String.fromCharCode(65 + correctOption.question_option_index);
        setCorrectOptionText(`${optionLetter}. ${correctOption.question_option_text}`);
      }
    }
  }, [quizData, currentQuestionIndex]);

  useEffect(() => {
    const fetchQuizResults = async () => {
      if (showGraph && quizData[currentQuestionIndex]?.quiz) {
        try {
          const response = await fetch(
            `https://quiz.kgtopg.com/get-quiz-results/${quizData[currentQuestionIndex].quiz.quiz_id}`,
          );
          const data = await response.json();
          setQuizResults(data);
        } catch (error) {
          console.error("Error fetching quiz results:", error);
        }
      }
    };

    fetchQuizResults();
  }, [showGraph, quizData, currentQuestionIndex]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="value">{`Responses: ${payload[0].value}`}</p>
          <p className="option-text">{payload[0].payload.text}</p>
        </div>
      );
    }
    return null;
  };
  const CustomLabel = (props) => {
    const { x, y, value } = props;
    return (
        <text 
          x={x + props.width / 2} 
          y={y - 20} 
          fill="#666"
          textAnchor="middle"
          fontSize="16"
          fontWeight="bold"
        >
          {value}
        </text>
    );
  };

  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    const colors = {
      'A': '#1368CE',
      'B': '#D89E00',
      'C': '#E21B3C',
      'D': '#26890C'
    };
    
    return (
      <g transform={`translate(${x},${y})`}>
        <rect  x="-40" y="-4" width="80" height="40" fill={colors[payload.value]} />
        <text
          x="0"
          y="20"
          textAnchor="middle"
          fill="white"
          fontSize="18"
          fontWeight="bold"
        >
          {payload.value}
        </text>
      </g>
    );
  };
  const getChartData = () => {
    if (!quizData[currentQuestionIndex] || !quizResults?.data?.students) {
      const options =sortOptionsByIndex(quizData[currentQuestionIndex]?.options || []);
      return options.map((option) => {
        const optionLetter = String.fromCharCode(65 + option.question_option_index);
        return {
          name: optionLetter,
          value: 0,
          text: option.question_option_text,
          isCorrect: option.question_option_correct === 1,
        };
      });
    }

    const optionCounts = {
      A: 0,
      B: 0,
      C: 0,
      D: 0,
    };

    quizResults.data.students.forEach((student) => {
      if (student.selected_option in optionCounts) {
        optionCounts[student.selected_option]++;
      }
    });

    // Create chart data array
    const options =  sortOptionsByIndex(quizData[currentQuestionIndex].options);
    return options.map((option) => {
      const optionLetter = String.fromCharCode(65 + option.question_option_index);
      return {
        name: `${optionLetter}`,
        value: optionCounts[optionLetter] || 0,
        text: option.question_option_text,
        isCorrect: option.question_option_correct === 1,
        color: option.question_option_correct === 1 ? "#26890C" : "#E21B3C",
      };
    });
  };

  const currentQuestion = quizData[currentQuestionIndex]?.quiz || null;
  const hasValidQuestion = currentQuestion && currentQuestion.question_text;

  const OptionSymbol: React.FC<{ type: string; className?: string }> = ({
    type,
    className = "",
  }) => {
    switch (type) {
      case "triangle":
        return (
          <svg className={`w-6 h-6 ${className}`} viewBox="0 0 24 24">
            <polygon points="12,2 2,22 22,22" fill="currentColor" />
          </svg>
        );
      case "diamond":
        return (
          <svg className={`w-6 h-6 ${className}`} viewBox="0 0 24 24">
            <rect
              x="4"
              y="4"
              width="16"
              height="16"
              transform="rotate(45 12 12)"
              fill="currentColor"
            />
          </svg>
        );
      case "circle":
        return (
          <svg className={`w-6 h-6 ${className}`} viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="10" fill="currentColor" />
          </svg>
        );
      case "square":
        return (
          <svg className={`w-6 h-6 ${className}`} viewBox="0 0 24 24">
            <rect x="4" y="4" width="16" height="16" fill="currentColor" />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="QuizContainer">
    {loading && isFirstQuestion ? (
      <div className="waiting-animation">
        <CircularProgress color="primary" />
        <span className="waiting-text">
          Waiting for recent questions...
        </span>
      </div>
    ) : (
      <div className="QuestionSection">
        {timerActive && questionVisible && hasValidQuestion && (
          <div className="TimerContainer">
            <div className="TimerCircle">
              <div
                className="timer-background"
                style={{
                  background: `conic-gradient(#4CAF50 ${
                    (timeLeft / QUIZ_TIME) * 100
                  }%, #f3f3f3 0)`,
                }}
              >
                <div className="timer-content">
                  <span className="timer-text">{timeLeft}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {!showGraph &&
          questionVisible &&
          hasValidQuestion &&
          !showWaitingAnimation && (
            <h5 className="QuestionText">
              {currentQuestion
                ? currentQuestion.question_text
                : "No question available"}
            </h5>
          )}
      </div>)}

      {showWaitingAnimation && (
        <div className="waiting-animation">
          <CircularProgress color="primary" />
          <span className="waiting-text">
            Waiting for the teacher to complete the Scanning..
          </span>
        </div>
      )}

      {!showWaitingAnimation &&
        !showGraph &&
        showOptions &&
        questionVisible &&
        quizData[currentQuestionIndex] &&
        hasValidQuestion && (
          <div className="OptionsSection">
            <div className="options-grid">
            {sortOptionsByIndex(quizData[currentQuestionIndex].options).map(
                (option) => {
                  // const symbols = ["triangle", "diamond", "circle", "square"];
                  const optionLetter = String.fromCharCode(65 + option.question_option_index);

                  return (
                    <div
                      key={option.quiz_question_option_id}
                      className="option-card"
                      style={{ backgroundColor: "white" }}
                    >
                      <div className="option-content">
                        {/* <OptionSymbol
                          type={symbols[index]}
                          className="option-symbol"
                        /> */}
                        <span className="option-letter" style={{ fontSize:"28px" }}>
                        {optionLetter}
                        </span>
                        <span className="option-text">
                          {option.question_option_text}
                        </span>
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          </div>
        )}

      {showGraph && quizResults && getChartData() && (
        <div className="results-container">
          <div className="GraphSection" style={{ width: "90%", height: 350 }}>
            <ResponsiveContainer>
              <BarChart
                data={getChartData()}
                margin={{ top: 40, right: 30, left: 30, bottom: 40 }}
                barGap={0}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis
                  dataKey="name"
                  tick={<CustomXAxisTick />}
                  axisLine={false}
                  tickLine={false}
                  dy={10}
                />
                {/* 
                <YAxis
                  tick={{ fill: "#666", fontSize: 17 }}
                  axisLine={{ stroke: "#999" }}
                  label={{
                    value: "Number of Responses",
                    angle: -90,
                    position: "insideLeft",
                    style: { fill: "#666" },
                  }}
                /> */}
                <Tooltip
                  content={
                    <CustomTooltip
                      active={undefined}
                      payload={undefined}
                      label={undefined}
                    />
                  }
                />
                <Bar dataKey="value" radius={[8, 8, 0, 0]} maxBarSize={80}>
                  {getChartData().map((entry, index) => {
                      let color = "white";
                      switch(index) {
                        case 0: color = '#1368CE'; break;
                        case 1: color =  '#D89E00';break;
                        case 2: color =  '#E21B3C'; break;
                        case 3: color = '#26890C'; break;
                        default: color = '#E21B3C';
                      }
                      
                      return (
                        <Cell 
                          key={`cell-${index}`}
                          fill={color}
                        />
                      );
                    })}
                    <LabelList
                      dataKey="value"
                      position="top"
                      content={CustomLabel}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          <div className="correct-answer-section">
            <div className="correct-answer-badge">
              <span className="correct-label">Correct Answer:</span>
              <span className="answer-text">{optionLetters[correctOptionIndex]}</span>
            </div>
            {quizResults.data.students && (
              <div className=".correct-answer-section">
                <span className="correct-label">Total Responses: </span>
                <span className="answer-text">
                  {quizResults.data.students.length}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizComponent;
